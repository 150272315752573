import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'order_items',
  attributes: [
    'id',
    'price',
    'quantity',
    'created_at',
    'variant',
    'variant_id',
    'order',
    'adjustment_total',
    'promotion_total',
    'use_type',
    'is_installment_available',
    'bundle_info',
    'event_price_id',
    'promoter_share_variant_ship_id',
    'cover'
  ],
  editableAttributes: ['quantity', 'variant_id']
}

export default class OrderItem extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...

  get subTotal() {
    return this.price * this.quantity + this.adjustment_total
  }

  coverImageThumb() {
    return this.cover.url
  }
}
